import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import "./profile.css";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import Footer from "../../components/Footer/Footer";
import swal from "sweetalert";
import Confetti from "react-confetti";

function Home() {
    const [isVisible, setVisible] = useState(null);
    const [profile, setProfile] = useState(null);
    const [start, setStart] = useState(false);
    const navigate = useNavigate();
    const [showConfetti, setShowConfetti] = useState(false);
    const {
        watch,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        navigate("/game1")
    }, []);

    return (
        <>
            <div className="app1">
                <div className="header">JM DESIGN</div>
                {profile != null ? (
                    <>
                        <div className="detail_id_home">
                            <div className="detail_id" style={{ position: "unset" }}>
                                <div className="item_id">ID : {Number(profile?.iduser) + 3000}</div>
                                <div style={{ fontWeight: "500" }}>
                                    Số điểm: <b>{Math.floor(profile?.money).toLocaleString()}</b>
                                </div>
                            </div>
                            <div
                                style={{
                                    fontWeight: "600",
                                    padding: "30px 0 10px",
                                    fontSize: "18px"
                                }}>
                                TRUNG TÂM ĐIỀU PHỐI
                            </div>
                        </div>
                    </>
                ) : null}
                <div style={{ padding: "20px" }}>
                    <div
                        onClick={() => {
                            localStorage.setItem("banner", "3");
                            navigate("/game3");
                        }}>
                        <img src={require("../../img/banner1.png")} />
                    </div>
                </div>
                <div style={{ padding: "0 20px 20px" }}>
                    <div
                        onClick={() => {
                            localStorage.setItem("banner", "4");
                            navigate("/game3");
                        }}>
                        <img src={require("../../img/banner2.png")} />
                    </div>
                </div>
                <div style={{ padding: "0 20px 20px" }}>
                    <img src={require("../../img/heart.png")} style={{ maxWidth: "200px", filter: "hue-rotate(225deg)" }} />
                </div>
            </div>
            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Home;
